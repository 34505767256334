/* eslint-disable */
import React, { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import {
	MAGIC_NUMBERS,
	APP_MARKETS,
	APP_LOCATIONS,
	libraries,
} from "../../constant";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import notify from "../../services/toast";
import { MdLocalSee, MdUpload, MdOutlineAttachFile } from "react-icons/md";
import { sortBy } from "lodash";
import submitApplication from "../../pages/philanthropy/give_scholarships/submitApplication";
import Spinner from "../../components/spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
	Input,
	SelectInputs,
	Upload,
} from "../../components/formvalidate/Input";
import config from "../../config";
import GiveScholarshipLandingPage from "../../components/app_terms_landing_page/GiveScholarshipLandingPage";

function ScholarshipPrompt() {
	const { userState } = useUserContext();
	const navigate = useNavigate();
	console.log(userState.accessToken);
	// Payload/application information
	const [applicationId, setApplicationId] = React.useState(null);
	const [submitting, setSubmitting] = React.useState(false);
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [orgName, setOrgName] = React.useState("");
	const [orgMission, setOrgMission] = React.useState("");
	const [website, setWebsite] = React.useState("");
	// const [orgAlign, setOrgAlign] = React.useState("");
	const [locationUsage, setLocationUsage] = React.useState(""); // New field
	const [threeChallenges, setThreeChallenges] = React.useState(""); // New field
	const [funding, setFunding] = React.useState(""); // New field
	const [annualEvents, setAnnualEvents] = React.useState(""); // New field
	const [volunteers, setVolunteers] = React.useState(""); // New field
	const [businessVideo, setBusinessVideo] = React.useState("");  // New field
	const [orgAmount, setOrgAmount] = React.useState("");
	const [orgImpact, setOrgImpact] = React.useState("");
	const [causes, setCauses] = React.useState([]);
	const [coLocation, setCOLocation] = React.useState("");
	const [idOption, setIdOption] = React.useState("");
	const [idFile, setIdFile] = React.useState(null);
	const [idReason, setIdReason] = React.useState(null);
	const [orgSponsored, setOrgSponsored] = React.useState("");
	const [market, setMarket] = React.useState("");
	const [type, setType] = React.useState("give");
	const status = "pending";
	const [businessAddress, setBusinessAddress] = useState("");
	const [orgOffer, setOrgOffer] = useState("");
	const [orgUses, setOrgUses] = useState("");
	const [logoImage, setLogoImage] = React.useState(null);
	// const [reapplicant, setReapplicant] = useState("");
	const createdAt = new Date();
	const [autoComplete, setAutoComplete] = useState(null);
	//Const location = useLocation();
	const nonprofitId = location.state;
	// End application data
	const [appSuccessModal, setAppSuccessModal] = React.useState(false);
	const fileInputRef = React.useRef(null);
	const handleButtonClick = React.useCallback(
		() => fileInputRef.current.click(),
		[]
	);
	const fileExplorerRef = React.useRef(null);
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries,
		region: "US",
	});

	// File Upload Information
	const imageCriteria = {
		requiredSize: 6e6,
		requiredDimensions: { width: 1000, height: 800 },
	};
	const imageCriteriaEventOwner = {
		requiredSize: 5e6,
		requiredDimensions: { width: 400, height: 400 },
	};

	function PhotoDisplay({ callback }) {
		return (
			<MdLocalSee
				onClick={callback}
				className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
			/>
		);
	}
	function FileDisplay({ callback }) {
		return (
			<MdOutlineAttachFile
				onClick={callback}
				className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
			/>
		);
	}

	const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" },
	];

	const scholarOPTIONS = [
		{ value: "no", label: "No, this is my first time applying." },
		{
			value: "yes",
			label: "Yes, I am a scholarship recipient and am re-applying.",
		},
	];

	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;

			setMarket(value);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		} else {
			setMarket(null);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		}
	};

	const generateCohatchMarket = () => {
		const cohatchMarket = [];

		for (const market in APP_MARKETS) {
			cohatchMarket.push(APP_MARKETS[market]);
		}

		return cohatchMarket.flat();
	};

	const generateCohatchLocation = () => {
		if (methods.watch().market) {
			const cohatchLocation = [];
			cohatchLocation.push(APP_LOCATIONS[methods.watch().market]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};
	const onLoad = (autoComplete) => {
		setAutoComplete(autoComplete);
	};
	const onPlaceChanged = () => {
		if (autoComplete !== null) {
			const {
				formatted_address,
				geometry: {
					location: { lat, lng },
				},
			} = autoComplete.getPlace();
			const latitude = lat();
			const longitude = lng();
			setBusinessAddress({
				address: formatted_address,
				coordinates: { lat: latitude, lng: longitude },
				location: { type: "Point", coordinates: [longitude, latitude] },
			});
		} else {
			console.log("Autocomplete is not loaded yet");
		}
	};

	const CAUSES = [
		{ value: "Animals", label: "Animals" },
		{ value: "Arts, Culture & Humanity", label: "Arts, Culture & Humanity" },
		{ value: "Climate", label: "Climate" },
		{ value: "Community Development", label: "Community Development" },
		{ value: "Conservation", label: "Conservation" },
		{ value: "Consumption", label: "Consumption" },
		{ value: "Crisis", label: "Crisis" },
		{ value: "Education", label: "Education" },
		{ value: "Energy", label: "Energy" },
		{ value: "Equality", label: "Equality" },
		{ value: "Family", label: "Family" },
		{ value: "Food", label: "Food" },
		{ value: "Health", label: "Health" },
		{ value: "Homeless", label: "Homeless" },
		{ value: "Jobs", label: "Jobs" },
		{ value: "Peace and Justice", label: "Peace and Justice" },
		{ value: "Refugees", label: "Refugees" },
		{ value: "Religion", label: "Religion" },
		{ value: "Water & Sanitation", label: "Water & Sanitation" },
		{ value: "Other", label: "Other" },
	];

	const OPTIONS = [
		{ value: "Yes", label: "Yes" },
		{ value: "No", label: "No" },
	];

	//  const handleUploadedFile = (event) => {
	//    const file = event.target.files[0];

	//    const urlImage = URL.createObjectURL(file);

	//    setIdFile(urlImage);
	//  };

	// form
	const methods = useForm();

	const onSubmit = methods.handleSubmit((data) => {
		handleFormSubmit(data);
	});

	const handleFormSubmit = async (data) => {
		setSubmitting(true);
		try {

			const payload = {
				firstName,
				lastName,
				email,
				website,
				businessAddress,
				phoneNumber,
				orgName,
				orgMission,
				locationUsage,
				threeChallenges,
				funding,
				annualEvents,
				volunteers,
				businessVideo,
				// orgAlign,
				orgAmount,
				orgImpact,
				orgSponsored,
				causes,
				type,
				// nonprofitId,
				market,
				orgOffer,
				//orgUses,
				idNumber: {
					idOption: idOption,
					idFile: idFile,
					idReason: idReason,
				},
				appStatus: status,
				cmStatus: status,
				mlStatus: status,
				// reapplicant,
				coLocation,
				logoImage,
				createdAt,
			};
			const response = await submitApplication(payload, userState.accessToken);
			if (response.status === "success") {
				setApplicationId(response.data.id);
				setSubmitting(false);
			} else {
				setSubmitting(false);
				let displayMessage = response.message || "An error occurred.";
				console.log(displayMessage);
			}
		} catch (error) {
			setSubmitting(false);
			console.error("Submission error: ", error);
		}
	};


	const handleIdFileUpload = (result) => {
		setIdFile({
			idFile: result,
		});
	};

	const handleLogoUploads = (result) => {
		setLogoImage({
			logoImage: result,
		});
	};

	const handleLogoUpload = async (data) => {
		try {
			const logo = methods.watch().logoImage;
			const folder = "logo_photos";
			const formData = new FormData();
			formData.append("width", 0);
			formData.append("height", 0);
			formData.append("folder", folder);
			formData.append("logo", logo[0]);
			formData.append("name", logo[0].name);
			const BASE_API_URL = config["API_BASE_ENDPOINT"];
			const response = await fetch(`${BASE_API_URL}/uploads/logos`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${userState.accessToken}`,
				},
			});
			if (response.ok) {
				const jsonResponse = await response.json();
				handleLogoUploads(jsonResponse.data);
			} else {
				console.log(
					"An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.",
					"error"
				);
				return;
			}
		} catch (error) {
			console.log("Something went wrong. Please try again later.", "error");
		}
	};

	const handleFileUpload = async (data) => {
		try {
			const file = methods.watch().idFile;
			const folder = "501c3_photos";
			const formData = new FormData();
			formData.append("width", 0);
			formData.append("height", 0);
			formData.append("folder", folder);
			formData.append("file", file[0]);
			formData.append("name", file[0].name);
			const BASE_API_URL = config["API_BASE_ENDPOINT"];
			const response = await fetch(`${BASE_API_URL}/uploads/files`, {
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${userState.accessToken}`,
				},
			});
			if (response.ok) {
				const jsonResponse = await response.json();
				handleIdFileUpload(jsonResponse.data);
			} else {
				notify(
					"An error occurred while uploading file. Please check your file size. If this error persists please contact support@cohatchplus.com.",
					"error"
				);
				return;
			}
		} catch (error) {
			console.log("Something went wrong. Please try again later.", "error");
		} finally {
			console.log("done");
		}
	};



	React.useEffect(() => {
		setType("give");
		// setReapplicant(methods.watch().reapplicant);
		setFirstName(methods.watch().firstName);
		setLastName(methods.watch().lastName);
		setEmail(methods.watch().email);
		setPhoneNumber(methods.watch().phoneNumber);
		setOrgName(methods.watch().orgName);
		setWebsite(methods.watch().website);
		setBusinessAddress(methods.watch().address);
		setOrgMission(methods.watch().orgMission);
		setLocationUsage(methods.watch().locationUsage);
		setThreeChallenges(methods.watch().threeChallenges);
		setFunding(methods.watch().funding);
		setAnnualEvents(methods.watch().annualEvents);
		setVolunteers(methods.watch().volunteers);
		setBusinessVideo(methods.watch().businessVideo);
		// setOrgAlign(methods.watch().orgAlign);
		setOrgImpact(methods.watch().orgImpact);
		setCauses(methods.watch().causes);
		setIdOption(methods.watch().idOption);
		//handleFileUpload(methods.watch().idFile)
		setCOLocation(methods.watch().coLocation);
		//handleLogoUpload(methods.watch().logoImage)
		setMarket(methods.watch().market);
		setOrgAmount(methods.watch().orgAmount);
		setOrgSponsored(methods.watch().orgSponsored);
		setIdReason(methods.watch().idReason);
		//handleLogoUpload(methods.watch());
	});

	React.useEffect(() => {
		handleFileUpload();
	}, [methods.watch().idFile]);

	React.useEffect(() => {
		handleLogoUpload();
	}, [methods.watch().logoImage]);

	return (
		<>
			{applicationId ? (
				<GiveScholarshipLandingPage applicationId={applicationId} reapplicant={"no"} />
			) : (
				<div className="p-10 flex justify-center">
					<div className="max-w-7xl  overflow-hidden shadow rounded-lg">
						<div className="bg-success-800">
							<div className="max-w-7xl mx-auto  sm:py-24 sm:px-6 lg:px-8">
								<div className="text-center">
									<p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
										Apply for the Give Scholarship
									</p>
									<p className="max-w-xl mt-5 mx-auto text-xl text-gray-200">
										If you’re a nonprofit looking to create more good in our
										communities, we’re here to help. Apply for our Give
										Scholarship to get access to our space while driving your
										mission forward.
										<p>
											<br />
											<b>
												Applications are open year round. Scholarships will be
												awarded on a rolling basis.
											</b>
										</p>
									</p>
								</div>
							</div>
						</div>

						<div className="bg-white flex justify-center">
							<div className="py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-8">
								<FormProvider {...methods}>
									<form
										onSubmit={onSubmit}
										noValidate
										autoComplete="off"
										className="container"
									>
										<div className="bg-white max-w-lg mx-auto lg:max-w-none">
											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="First Name"
														name="firstName"
														type="input"
														id="name"
														placeholder="First Name"
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
												<div className="w-full">
													<Input
														type="text"
														name="lastName"
														id="lastName"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Last Name"
														placeholder="Enter Last Name"
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="w-full flex mb-6">
												<div className="w-full">
													<Input
														label="Email"
														type="text"
														name="email"
														id="email"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														placeholder="Enter Email"
														validation={{
															required: {
																value: true,
																message: "required",
															},
															pattern: {
																value:
																	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
																message: "not a valid email address",
															},
														}}
													/>
												</div>
											</div>
											<div className="w-full flex gap-3 mb-6">
												<div className="w-full">
													<Input
														type="input"
														name="phoneNumber"
														id="phoneNumber"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Phone Number"
														placeholder="enter phone number"
														validation={{
															required: {
																value: true,
																message: "required",
															},
															pattern: {
																value: /^[0-9]{10}$/,
																message: "not a valid phone number",
															},
														}}
													/>
												</div>
											</div>
											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="text"
														name="orgName"
														id="orgName"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Organization Name"
														placeholder="Enter Organization Name"
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="string"
														name="website"
														id="website"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Organization Website"
														placeholder="enter website"
														validation={{
															required: {
																value: false,
															},
															pattern: {
																value:
																	/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,255}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
																message: "not a valid website",
															},
														}}
													/>
												</div>
											</div>
											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													{isLoaded ? (
														<Autocomplete
															onLoad={onLoad}
															onPlaceChanged={onPlaceChanged}
														>
															<div className="w-full flex gap-3">
																<div className="w-full">
																	<Input
																		className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
																		label="Organization Address"
																		placeholder="enter address"
																		validation={{
																			required: {
																				value: true,
																				message: "required",
																			},
																		}}
																		type="text"
																		name="address"
																		id="address"
																	/>
																</div>
															</div>
														</Autocomplete>
													) : (
														<Spinner displayText="Loading..." />
													)}
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														multiline={true}
														rows="3"
														className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														type="textarea"
														name="orgMission"
														id="orgMission"
														label="Description of your organization and mission?:"
														placeholder="description here..."
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											{/* <div className="mb-6 w-full flex gap-3">
											<div className="w-full">
												<Input
													type="textarea"
													name="orgAlign"
													id="orgAlign"
													multiline={true}
													rows={3}
													className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
													label="What aspect of COhatch's mission do you think most aligns with your organization's mission?:"
													placeholder="description here..."
													validation={{
														required: {
															value: true,
															message: "required",
														},
													}}
												/>
											</div>
										</div> */}

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="textarea"
														name="orgImpact"
														id="orgImpact"
														multiline={true}
														rows={3}
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="How does your organization impact the local community? (Ex. event, providing necessary services, etc.):"
														placeholder="description here..."
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="textarea"
														name="locationUsage"
														id="locationUsage"
														multiline={true}
														rows={3}
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="How are you planning to use our location?"
														placeholder="Describe how you plan to use the location..."
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="textarea"
														name="threeChallenges"
														id="threeChallenges"
														multiline={true}
														rows={3}
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="What are your organization's top three Challenges or barriers?"
														placeholder="List your top three threeChallenges..."
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="textarea"
														name="funding"
														id="funding"
														multiline={true}
														rows={3}
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="How is your organization funded?"
														placeholder="Explain your organization's funding..."
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="textarea"
														name="annualEvents"
														id="annualEvents"
														multiline={true}
														rows={3}
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Does your organization have any annual events? If so, please tell us about your events."
														placeholder="Describe your annual events..."
														validation={{
															required: {
																value: true, 
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<Input
														type="textarea"
														name="volunteers"
														id="volunteers"
														multiline={true}
														rows={3}
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Does your organization use volunteers? If so, tell us how?"
														placeholder="Explain how your organization uses volunteers..."
														validation={{
															required: {
																value: true, 
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="w-full flex gap-3 mb-6">
												{/* Business Video */}
												<div className="w-full">
													<label className="font-semibold capitalize">
														Please record a 3 minute or less video talking about
														your business and why you'd like to be a part of the
														COhatch ecosystem. Please utilize a service like loom
														where you can record yourself and grab a link, for
														free! Loom:
														<a
															href="https://www.loom.com/signup"
															target="_blank"
															className="text-blue-900 underline lowercase"
														>
															https://www.loom.com/signup
														</a>
													</label>
													<span> (Please only add links to videos here)</span>
													<Input
														className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														type="input"
														name="businessVideo"
														placeholder="Link here..."
														id="businessVideo"
														validation={{
															required: {
																value: true, 
																message: "required",
															},
															pattern: {
																value:
																	/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,255}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
																message: "not a valid website",
															},
														}}
													/>
												</div>
											</div>

											<div className="mb-6 w-full flex gap-3">
												<div className="w-full">
													<div>
														<div className="w-full flex items-center gap-3">
															<div className="w-full">
																<div className="mt-1">
																	<SelectInputs
																		label="What cause does your organization align with most:"
																		name="causes"
																		id="causes"
																		options={CAUSES}
																		validation={{
																			required: {
																				value: true,
																				message: "required",
																			},
																		}}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="w-full flex gap-3">
												<div className="w-full">
													<SelectInputs
														label="Do you have a 501C3?:"
														id="idOption"
														name="idOption"
														options={sortBy(OPTIONS, (item) => item.value)}
														noOptionsMessage="No options found"
														setSelectedValue={(event) => setIdOption(event.value)}
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>
											{methods.watch().idOption === "No" && (
												<div className="mt-3 mb-4 ">
													<p className="text-sm text-red-400 text-right">*</p>
													<Input
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Explain why not:"
														multiline={true}
														rows={3}
														type="textarea"
														name="idReason"
														id="idReason"
														value={idReason}
														onChange={(event) => setIdReason(event.target.value)}
													/>
												</div>
											)}
											{methods.watch().idOption == "Yes" && (
												<>
													<div className="mt-5 mb-2">
														<Upload
															label="Upload a copy of your 501c3 document"
															name="idFile"
															type="file"
															id="idFile"
															validation={{
																required: {
																	value: true,
																	message: "required",
																},
															}}
														/>
														<div>
															Files MUST be a PDF file
															<span className="ml-3 text-lg text-red-400">*</span>
														</div>
													</div>
												</>
											)}
											<div className="mt-5">
												<Upload
													label="Logo Image"
													type="file"
													name="logoImage"
													id="logoImage"
													validation={{
														required: {
															value: false,
														},
													}}
												/>
												<div>
													Image MUST be a PNG file
													<span className="ml-3 text-lg text-red-400">*</span>
												</div>
											</div>

											<div className="w-full mt-3 flex gap-3 mb-6">
												<div className="w-full">
													<SelectInputs
														label="COhatch City:"
														name="market"
														id="market"
														options={generateCohatchMarket()}
														noOptionsMessage="No cities found"
														placeholder="Select a COhatch City"
														setSelectedValue={handleMarketChange}
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>

												<div className="w-full flex  gap-3">
													<div className="w-full ">
														<SelectInputs
															label="COhatch Location"
															id="coLocation"
															name="coLocation"
															options={generateCohatchLocation()}
															noOptionsMessage="Select a COhatch Location"
															placeholder={methods.watch().coLocation}
															validation={{
																required: {
																	value: true,
																	message: "required",
																},
															}}
														/>
													</div>
												</div>
											</div>
											<div className="w-full flex gap-3 mb-6">
												<div className="w-full">
													<SelectInputs
														label="How many employees need access to COhatch?:"
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														name="orgAmount"
														id="orgAmount"
														options={scorecardOPTIONS}
														setSelectedValue={(event) =>
															setOrgAmount(event.value)
														}
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>

											<div className="w-full flex gap-3 mt-6">
												<div className="mb-8 w-full">
													<Input
														className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
														label="Are you sponsored by a COhatch employee? If so, who?:"
														type="text"
														name="orgSponsored"
														id="orgSponsored"
														validation={{
															required: {
																value: true,
																message: "required",
															},
														}}
													/>
												</div>
											</div>
											<div className="mt-4 ">
												<div className="w-full mt-4 flex justify-between items-center">
													<div>
														<button
															className="cursor-pointer inline-flex items-center justify-center gap-2 px-3 py-2 border border-transparent text-md font-medium rounded shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-500"
															type="submit"
														>
															Submit
														</button>
													</div>
												</div>
											</div>
										</div>
									</form>
								</FormProvider>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ScholarshipPrompt;
